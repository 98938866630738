<template>
  <div class="letter_detail_page">
    <div class="top">
      <div class="title">消息详情</div>
      <div class="code_block">
        <el-button @click="$router.go(-1)" class="table_top_btn_default"> &lt; 返回列表</el-button>
      </div>
    </div>
    <div class="content">
        <div class="title">{{info.messageTitle}}</div>
        <div class="time">时间：{{common.dateFormat(info.createtime * 1000)}}</div>
        <pre v-html="info.messageContent"></pre>
        <!-- <div v-html=""></div> -->
    </div>
  </div>
</template>

<script>
  export default {
    name: 'letterDetail',
    components: {},
    data() {
      return {
          info: {}
      }
    },
    created() {},
    mounted() {
        this.api.admin.messageInfo({messageId:this.$route.query.messageId}).then(res=>{
            this.info = res.data
        })
    },
    methods: {

    },
  }
</script>

<style lang="scss" scoped>
  .letter_detail_page {
    .top {
      @include flex(row, space-between, center);
      border-bottom: 10px solid #cc1a30;
      padding-bottom: 10px;

      .title {
        font-size: 34px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        color: #cc1a30;
      }
    }
    .content{
        box-sizing: border-box;
        padding: 50px 200px 50px 0;
        .title {
          font-size: 34px;
          font-family: Alibaba PuHuiTi;
          font-weight: bold;
          text-align: center;
          margin-bottom: 10px;
        }
    
        .time {
          font-size: 24px;
          font-family: Alibaba PuHuiTi;
          font-weight: bold;
          text-align: right;
          margin-bottom: 20px;
        }
    }
  }
</style>
<style lang="scss">